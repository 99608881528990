import React from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    
    return (
            <div className='columna-puntaje'>
                <button className='boton-mandar' onClick={() => navigate('/salto')}>Salto</button>
                <button className='boton-mandar' onClick={() => navigate('/barra')}>Barra</button>
                <button className='boton-mandar' onClick={() => navigate('/viga')}>Viga</button>
                <button className='boton-mandar' onClick={() => navigate('/piso')}>Piso</button>
                <button className='boton-mandar' onClick={() => navigate('/puntaje')}>Agregar puntaje</button>
          
                <button className='boton-mandar2' onClick={() => navigate('/resultados')}>Resultados</button>
                <button className='boton-mandar2' onClick={() => navigate('/resultadosSalto')}>Resultados Salto</button>
                <button className='boton-mandar2' onClick={() => navigate('/resultadosBarra')}>Resultados Barra</button>
                <button className='boton-mandar2' onClick={() => navigate('/resultadosViga')}>Resultados Viga</button>
                <button className='boton-mandar2' onClick={() => navigate('/resultadosPiso')}>Resultados Piso</button>
            </div>
    );
};

export default Home;
