import React from 'react';
import './MostrarPuntaje.css';

const MostrarPuntaje = ({aparato, nombre, club, puntaje}) => {
    return (
        /* fondo */
        <div className={`fondo-puntaje ${aparato}`}>
            {/* primera fila*/}
            <div className='fila'>
                {/* logo colegio */}
                {club && (
                    <img className="imagen-colegio" src={`/images/${club}.jpg`}/>
                )}
                {/* nombre */}
                {nombre && (
                    <p className='nombre-atleta'>{nombre}</p>
                )}
            </div>

             {/* segunda fila*/}
             <div className='fila-puntaje'>
                {/* aparato imagen*/}
                {/* <img className="imagen-aparato" src={`/images/${aparato}.jpg`}/> */}
                {/* puntaje*/}
                {puntaje && (
                     <p className='puntaje-atleta'>{parseFloat(puntaje).toFixed(3)}</p>
                )}
                {/* {puntaje == 0 && (
                    <p className='puntaje-atleta'>0.000</p>
                )} */}
            </div>      
        </div>
    );
};

export default MostrarPuntaje;