import React, {useState} from 'react';
import './IniciarSesion.css';
import { auth } from '../firebase.js';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const IniciarSesion = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleEmailPasswordLogin = async (email, password) => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            auth.currentUser.getIdToken(true);
            navigate('/home');
        } catch (error) {
            setError('Error');
        }
    };

    return (
        <div className='columna-puntaje'>
            <h1>Iniciar Sesión</h1>
            <div className='login'>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    const email = e.target.email.value;
                    const password = e.target.password.value;
                    handleEmailPasswordLogin(email, password);
                }}>
                <input type="email" name="email" placeholder="Correo" required />
                <input
                    name="password"
                    placeholder="Contraseña"
                    required
                />
                <button className='boton-mandar' type="submit">Iniciar sesión</button>
                <p className='mensaje-error'>{error}</p>
                </form>
            </div>
        </div>
    );
};

export default IniciarSesion;