import React, { useEffect, useState } from 'react';
import './AparatoPuntaje.css';
import io from 'socket.io-client';
import { socket } from '../socket';

const AgregarPuntaje = () => {
    const [nombre, setNombre] = useState('');
    const [nombres, setNombres] = useState([]);
    const [puntaje, setPuntaje] = useState('');
    const [aparato, setAparato] = useState('');
    const [club, setClub] = useState('');
    const [nivel, setNivel] = useState('');
    const [error, setError] = useState('');

    const [niveles, setNiveles] = useState([]);
    const [clubs, setClubs] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const parsedPuntaje = parseFloat(puntaje);
        if (!isNaN(parsedPuntaje) && parsedPuntaje >= 0.0 && parsedPuntaje <= 20.0 && nombre && puntaje && club && nivel && aparato) {
            const data = {
                nombre: nombre,
                puntaje: parsedPuntaje,
                club: club,
                nivel: nivel,
                aparato: aparato 
            };
            if (aparato === 'Piso') {
                socket.emit('piso-puntaje', data);
            } else if (aparato === 'Viga') {
                socket.emit('viga-puntaje', data);
            } else if (aparato === 'Barra') {
                socket.emit('barra-puntaje', data);
            } else if (aparato === 'Salto') {
                socket.emit('salto-puntaje', data);
            }
            setError('');
        } else {
            setError('Error');
        }
    };

    const fetchNiveles = async () => {
        try {
            const response = await fetch(`https://infinity-app-21a0ce6e62e5.herokuapp.com/obtener_niveles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                });
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();
            console.log("fetchniveles", data.niveles);
            setNiveles(data.niveles);
        } catch (error) {
            console.error('Error obteniendo niveles', error);
        }
    };

    const fetchClubs = async () => {
        try {
            const response = await fetch(`https://infinity-app-21a0ce6e62e5.herokuapp.com/obtener_clubs`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                });
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();
            console.log("fetchclubs", data.clubs);
            setClubs(data.clubs);
        } catch (error) {
            console.error('Error obteniendo niveles', error);
        }
    };

    const fetchNombres = async () => {
        try {
            const response = await fetch(`https://infinity-app-21a0ce6e62e5.herokuapp.com/obtener_nombres?club=${club}&nivel=${nivel}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                });

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();
            const results = data.result;
            const nombres = results.map(item => `${item.nombre} ${item.apellido}`);
            setNombres(nombres);
        } catch (error) {
            console.error('Error obteniendo nombres', error);
        }
    };

    useEffect(() => {
        fetchNiveles();
        fetchClubs();
    }, []);

    useEffect(() => {
        if (club && nivel) {
            setNombres([]);
            fetchNombres();
        }
    }, [club, nivel]);

    return (
        <div className='columna-puntaje'>
            <h2 className='titulo'>Agregar Puntaje</h2>
            <form onSubmit={handleSubmit}>
            {/* aparato */}
            <div className='fila-puntaje'>
                <label for="nombre">
                    Aparato:
                </label>
                <select id="aparato" value={aparato} onChange={(e) => setAparato(e.target.value)}>
                    <option value="">--</option>
                    <option value="Salto">Salto</option>
                    <option value="Barra">Barra</option>
                    <option value="Viga">Viga</option>
                    <option value="Piso">Piso</option>
                </select>
            </div>
             {/* club */}
             <div className='fila-puntaje'>
                <label for="nombre">
                    Club:
                </label>
                <select id="club" value={club} onChange={(e) => setClub(e.target.value)}>
                    <option value="">--</option>
                    {clubs.map((club) => (
                        <option key={club} value={club}>
                            {club}
                        </option>
                    ))}
                </select>
            </div>
            {/* Nivel */}
            <div className='fila-puntaje' style={{marginBottom: '50px'}}>
                <label for="nombre">
                    Nivel:
                </label>
                <select id="nivel" value={nivel} onChange={(e) => setNivel(e.target.value)}>
                    <option value="">--</option>
                    {niveles.map((nivel) => (
                        <option key={nivel} value={nivel}>
                            {nivel}
                        </option>
                    ))}
                </select>
            </div>
            {/* nombre */}
            <div className='fila-puntaje'>
                <label for="nombre">
                    Nombre:
                </label>
                <select id="nombre" value={nombre} onChange={(e) => setNombre(e.target.value)}>
                    <option value="">--</option>
                    {nombres.map((name) => (
                        <option key={name} value={name}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>
            {/* puntaje */}
            <div className='fila-puntaje'>
                <label for="nombre">
                    Puntaje:
                </label>
                <input id="puntaje" value={puntaje} onChange={(e) => setPuntaje(e.target.value)}/>
            </div>
            {/* boton */}
            <button className='boton-mandar' type="submit">
                Mandar
            </button>
            {error && (
                <p className='mensaje-error'>{error}</p>
            )}
            </form>
        </div>
    );
};

export default AgregarPuntaje;
