import React, { useState, useEffect } from 'react';
import './ResultadosIndividuales.css';
import jsPDF from 'jspdf';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { socket } from '../socket';

const ResultadosIndividuales = () => {

    const navigate = useNavigate();
    const [aparato, setAparato] = useState('');
    const [nivel, setNivel] = useState('');
    const [categoria, setCategoria] = useState('');
    const [niveles, setNiveles] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [results, setResults] = useState([]);

    function updateScreens() {
        

        if (results.length > 0 && aparato && nivel && categoria) {
            const data = {
                results: results,
                aparato: aparato,
                nivel: nivel,
                categoria: categoria
            };
            if (aparato === 'Piso') {
                socket.emit('piso-result', data);
            } else if (aparato === 'Viga') {
                socket.emit('viga-result', data);
            } else if (aparato === 'Barra') {
                socket.emit('barra-result', data);
            } else if (aparato === 'Salto') {
                console.log("mandando resultados de salto", data);
                socket.emit('salto-result', data);
            }
        }
    };

    const fetchNiveles = async () => {
        try {
            const response = await fetch(`https://infinity-app-21a0ce6e62e5.herokuapp.com/obtener_niveles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                });
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();
            setNiveles(data.niveles);
        } catch (error) {
            console.error('Error obteniendo niveles', error);
        }
    };

    const fetchCategorias = async () => {
        try {
            const response = await fetch(`https://infinity-app-21a0ce6e62e5.herokuapp.com/obtener_categorias?nivel=${nivel}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                });
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();
            setCategorias(data.categorias);
        } catch (error) {
            console.error('Error obteniendo niveles', error);
        }
    };

    // call API when the button is clicked if aparato, nivel and categoria not empty
    const fetchResults = async () => {
        if (!nivel || !aparato || !categoria) {
            return;
        }
        try {
            const response = await fetch(`https://infinity-app-21a0ce6e62e5.herokuapp.com/resultadosIndividuales?aparato=${aparato}&nivel=${nivel}&categoria=${categoria}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                });


            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();
            const results = data.result;
            results.sort((a, b) => parseFloat(b.puntaje) - parseFloat(a.puntaje));
            setResults(results);

        } catch (error) {
            console.error('Error obteniendo results', error);
        }
    };  

    const descargarPDF = async () => {
        try {
          const response = await fetch('https://infinity-app-21a0ce6e62e5.herokuapp.com/descargarPuntaje', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              aparato,
              nivel,
              categoria,
              results
            }),
          });
      
          if (!response.ok) {
            throw new Error(`Request failed with status: ${response.status}`);
          }
      
          // Once data retrieval is successful, initiate PDF download
        //   const pdfDownload = async () => {
        //     try {
        //       const pdfResponse = await fetch('https://infinity-app-21a0ce6e62e5.herokuapp.com/download-sheets-pdf');
      
        //       if (!pdfResponse.ok) {
        //         throw new Error(`PDF download failed with status: ${pdfResponse.status}`);
        //       }
      
        //       const blob = await pdfResponse.blob();
        //       const url = window.URL.createObjectURL(blob);
        //       const a = document.createElement('a');
        //       a.href = url;
        //       a.download = 'sheetsData.pdf';
        //       document.body.appendChild(a);
        //       a.click();
        //       a.remove();
        //       window.URL.revokeObjectURL(url);
        //     } catch (error) {
        //       console.error('Error downloading PDF:', error);
        //     }
        //   };
      
        //   await pdfDownload();
      
        } catch (error) {
          console.error('Error obtaining results:', error);
        }
      };
      
    


        // const doc = new jsPDF();
        // const pageHeight = doc.internal.pageSize.height;
        // const marginTop = 15;
        // const lineHeight = 10;
        // const itemHeight = 10;
    
        // let yOffset = marginTop;
    
        // doc.setFontSize(15).setFont(undefined, 'bold').text(`Resultados ${aparato} ${nivel} Categoría ${categoria}`, 15, yOffset);
        // yOffset += lineHeight * 2; // Adjust yOffset for title space
    
        // if (results.length > 0) {
        //     let currentIndex = 1;
        //     for (let i = 0; i < results.length; i++) {
        //         if (i > 0 && results[i].puntaje === results[i - 1].puntaje) {
        //             currentIndex = results[i - 1].index;
        //         } else if (i === 0) {
        //             currentIndex = 1;
        //         } else {
        //             currentIndex = results[i - 1].index + 1;
        //         }
        //         results[i].index = currentIndex;
    
        //         const nombreApellidoHeight = lineHeight;
        //         const puntajeHeight = lineHeight;
        //         const clubHeight = lineHeight;
    
        //         // Check if next block of text will exceed the page height
        //         if (yOffset + nombreApellidoHeight + puntajeHeight + clubHeight + itemHeight > pageHeight) {
        //             doc.addPage();
        //             yOffset = marginTop;
        //         }
    
        //         doc.setFontSize(12).setFont(undefined, 'bold').text(`${results[i].index}. ${results[i].nombre} ${results[i].apellido}`, 15, yOffset);
        //         yOffset += nombreApellidoHeight;
        //         doc.setFontSize(12).setFont(undefined, 'normal').text(`Puntaje: ${results[i].puntaje}`, 25, yOffset);
        //         yOffset += puntajeHeight;
        //         doc.text(`Club: ${results[i].club}`, 25, yOffset);
        //         yOffset += clubHeight + itemHeight; // Add itemHeight for space between items
        //     }
        // }
    
        // // Save PDF
        // doc.save(`Resultados_${aparato}_${nivel}_${categoria}.pdf`);
    //}
    
    

    useEffect(() => {
        fetchNiveles();
    }, []);

    useEffect(() => {
        if (nivel) {   
            fetchCategorias();
        }
    }, [nivel]);

    return (
        <div className='container'>

            {/* seleccionar aparato, nivel, categoría para buscar en la base de datos*/}
            <div className='row-select'>
            
                <div className='fila-select'>
                    <label for="nombre">
                        Aparato:
                    </label>
                    <select id="aparato" value={aparato} onChange={(e) => setAparato(e.target.value)}>
                        <option value="">--</option>
                        <option value="Salto">Salto</option>
                        <option value="Barra">Barra</option>
                        <option value="Viga">Viga</option>
                        <option value="Piso">Piso</option>
                        <option value="allaround">All Around</option>
                    </select>
                </div>

                <div className='fila-select'>
                    <label for="nombre">
                        Nivel:
                    </label>
                    <select id="nombre" value={nivel} onChange={(e) => setNivel(e.target.value)}>
                        <option value="">--</option>
                        {niveles.map((name) => (
                            <option key={name} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='fila-select'>
                    <label for="nombre">
                        Categoría:
                    </label>
                    <select id="nombre" value={categoria} onChange={(e) => setCategoria(e.target.value)}>
                        <option value="">--</option>
                        {categorias && categorias.map((name) => (
                            <option key={name} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>

                <button className='boton-calcular' onClick={fetchResults} type="submit">
                    Calcular
                </button>

            </div>

            {/* display  score */}
            {results.length > 0 && (
            <div className='results'>
                <div>
                    <h2>Resultados {aparato} {nivel} Categoría {categoria}</h2>
                    {results.map((result, index, array) => {
                        let currentIndex = index + 1;
                            
                        if (index > 0 && result.puntaje === array[index - 1].puntaje) {
                            currentIndex = array[index - 1].index;
                        } else if (index === 0) {
                            currentIndex = 1;
                        } else {
                            currentIndex = array[index - 1].index + 1;
                        }

                        array[index].index = currentIndex;
                        result.currentIndex = currentIndex;

                        return (
                            <div className='gimnasta-puntaje' key={index}>
                                <p>
                                    <strong>{array[index].index}. {result.nombre} {result.apellido}</strong>
                                </p>
                                <p>Puntaje: {result.puntaje}</p>
                                <p>Club: {result.club}</p>
                            </div>
                        );
                    })}
                </div>
                <button className='descargar-button' onClick={descargarPDF}>Descargar PDF</button> 
                <button className='descargar-button' onClick={updateScreens}>Refrescar pantalla</button>
            </div>
        )}



        </div>
    );
};

export default ResultadosIndividuales;
