import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Salto from './paginas/Salto';
import Barra from './paginas/Barra';
import Viga from './paginas/Viga';
import Piso from './paginas/Piso';
import Home from './paginas/Home';
import Resultados from './paginas/Resultados';
import AgregarPuntaje from './paginas/AgregarPuntaje';
import ProtectedRoute from './componentes/ProtectedRoute';
import IniciarSesion from './paginas/IniciarSesion';
import { auth } from './firebase.js';
import ResultadosSalto from './paginas/ResultadosSalto';
import ResultadosBarra from './paginas/ResultadosBarra';
import ResultadosViga from './paginas/ResultadosViga';
import ResultadosPiso from './paginas/ResultadosPiso';

// Responsable por dependiendo del URL mostrar pagina correspondiente
function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        return;
      } 
      setUser(null);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<IniciarSesion/>}/>
        <Route path="/home" element={<ProtectedRoute user={user} element={<Home />} />}/>
        <Route path="/salto" element={<ProtectedRoute user={user} element={<Salto />} />}/>
        <Route path="/barra" element={<ProtectedRoute user={user} element={<Barra />} />}/>
        <Route path="/viga" element={<ProtectedRoute user={user} element={<Viga />} />}/>
        <Route path="/piso" element={<ProtectedRoute user={user} element={<Piso />} />}/>
        <Route path="/puntaje" element={<ProtectedRoute user={user} element={<AgregarPuntaje />} />}/>
        <Route path="/resultados" element={<ProtectedRoute user={user} element={<Resultados />} />}/>
        <Route path="/resultadosSalto" element={<ProtectedRoute user={user} element={<ResultadosSalto />} />}/>
        <Route path="/resultadosBarra" element={<ProtectedRoute user={user} element={<ResultadosBarra />} />}/>
        <Route path="/resultadosViga" element={<ProtectedRoute user={user} element={<ResultadosViga />} />}/>
        <Route path="/resultadosPiso" element={<ProtectedRoute user={user} element={<ResultadosPiso />} />}/>
      </Routes>
    </Router>
  );
}

export default App;
