import React, { useState } from 'react';
import './Resultados.css';
import ResultadosIndividuales from './ResultadosIndividuales';
import ResultadosEquipo from './ResultadosEquipo';


const Resultados = () => {

    const [tab, setTab] = useState(0);

    return (
        <div>
            {/* Tab view */}
            <div className='tab-row'>
                <p className={`tab-name ${tab === 0 ? 'active-tab' : ''}`} onClick={() => setTab(0)}>Resultados Individuales</p>
                <p className={`tab-name ${tab === 1 ? 'active-tab' : ''}`} onClick={() => setTab(1)}>Resultados Equipo</p>
            </div>

            {/* Tab content */}
            {tab === 0 && <ResultadosIndividuales/>}
            {tab === 1 && <ResultadosEquipo/>}
        </div>  
    );
};

export default Resultados;
