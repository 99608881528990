import React from "react";
import { Route, Navigate } from "react-router-dom";

const ProtectedRoute = ({ element, user }) => {
    if (user) {
        return element;
    } else {
        return <Navigate to="/" />;
    }
}

export default ProtectedRoute;