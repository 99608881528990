import React, { useEffect, useState } from 'react';
import MostrarPuntaje from '../componentes/MostrarPuntaje';
import io from 'socket.io-client';
import { socket } from '../socket';

const Salto = () => {
    const [nombre, setNombre] = useState('');
    const [club, setClub] = useState('');
    const [puntaje, setPuntaje] = useState('');

    useEffect(() => {
        socket.on('saltodata', data => {
            setNombre(data.nombre);
            setClub(data.club);
            setPuntaje(data.puntaje); 
        });
    }, []);
    
    return (
        <div>
            <MostrarPuntaje aparato={"salto"} nombre={nombre} club={club} puntaje={puntaje}/>
        </div>
    );
};

export default Salto;
