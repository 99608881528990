import React, { useEffect, useState } from 'react';
import MostrarResultados from '../componentes/MostrarResultados';
import { socket } from '../socket';

const ResultadosPiso = () => {

    const [aparato, setAparato] = useState('');
    const [results, setResults] = useState([]);
    const [nivel, setNivel] = useState('');
    const [categoria, setCategoria] = useState('');

    useEffect(() => {
        // const socket = io('http://127.0.0.1:5000');

        socket.on('pisoresult', data => {
            if (data.aparato === 'Piso' && data.results.length > 0 ) {
                setAparato(data.aparato);
                setResults(data.results);
                setNivel(data.nivel);
                setCategoria(data.categoria);
            }
        });

        return () => {
            socket.disconnect();
        }

    }, []);

    return (
        <div>
            <MostrarResultados aparato={aparato} results={results} nivel={nivel} categoria={categoria}/>
        </div>
    );
};

export default ResultadosPiso;