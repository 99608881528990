import React, { useEffect, useState } from 'react';
import MostrarPuntaje from '../componentes/MostrarPuntaje';
import io from 'socket.io-client';
import { socket } from '../socket';

const Barra = () => {

    const [nombre, setNombre] = useState('');
    const [club, setClub] = useState('');
    const [puntaje, setPuntaje] = useState('');

    useEffect(() => {
        socket.on('barradata', data => {
            setNombre(data.nombre);
            setClub(data.club);
            setPuntaje(data.puntaje); 
        });
    }, []);

    return (
        <MostrarPuntaje aparato={"barra"} nombre={nombre} club={club} puntaje={puntaje}/>
    );
};

export default Barra;
