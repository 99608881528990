import React from 'react';
import './MostrarResultados.css';

const MostrarResultados = ({ aparato, results, nivel, categoria }) => {

    results = results.filter((result) => result.puntaje !== "0.000").slice(0, 10);


    return (
        <div className='fondo-puntaje'>
            <h2 className='title-resultados'>Resultados {aparato} {nivel} Categoría {categoria}</h2>

            {results && (
                <div className='table-container'>
                    <table className='score-table'>
                        <thead>
                            <tr>
                                <th>Posición</th>
                                <th>Nombre</th>
                                <th>Club</th>
                                <th>Puntaje</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.map((result, index) => (
                                <tr key={index + 1}>
                                    <td>{result.currentIndex}</td>
                                    <td>{result.nombre} {result.apellido}</td>
                                    <td>{result.club}</td>
                                    <td>{result.puntaje}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default MostrarResultados;