import React, { useState, useEffect } from 'react';
import './ResultadosEquipo.css';
import './ResultadosIndividuales.css';
import jsPDF from 'jspdf';

const ResultadosEquipo= () => {

    const [nivel, setNivel] = useState('');
    const [niveles, setNiveles] = useState([]);
    const [numeroGimnastas, setNumeroGimnastas] = useState();
    const [results, setResults] = useState([]);

    const fetchNiveles = async () => {
        try {
            const response = await fetch(`https://infinity-app-21a0ce6e62e5.herokuapp.com/obtener_niveles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                });
            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();
            console.log("fetchniveles", data.niveles);
            setNiveles(data.niveles);
        } catch (error) {
            console.error('Error obteniendo niveles', error);
        }
    };

    const fetchResultsEquipo = async () => {
        if (!nivel || !numeroGimnastas) {
            return;
        }
        try {
            const response = await fetch(`https://infinity-app-21a0ce6e62e5.herokuapp.com/resultadosEquipo?nivel=${nivel}&numero=${numeroGimnastas}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                });

            if (!response.ok) {
                throw new Error(`Request failed with status: ${response.status}`);
            }
            const data = await response.json();
            const results = data.result;
            console.log("results: ", results);
            setResults(results);

        } catch (error) {
            console.error('Error obteniendo results', error);
        }
    };

    const descargarPDF = async () => {
        try {
          const response = await fetch('https://infinity-app-21a0ce6e62e5.herokuapp.com/descargarPuntajeEquipo', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              nivel,
              results
            }),
          });
      
          if (!response.ok) {
            throw new Error(`Request failed with status: ${response.status}`);
          }
      
        } catch (error) {
          console.error('Error obtaining results:', error);
        }
      };


        // OLD CODE
        // const doc = new jsPDF();
        // const pageHeight = doc.internal.pageSize.height;
        // const marginTop = 15;
        // const lineHeight = 10;
        // const itemHeight = 10;
    
        // let yOffset = marginTop;
    
        // doc.setFontSize(15).setFont(undefined, 'bold').text(`Resultados Equipo ${nivel}`, 15, yOffset);
        // yOffset += lineHeight * 2; // Adjust yOffset for title space
    
        // if (results.length > 0) {
        //     let currentIndex = 1;
        //     for (let i = 0; i < results.length; i++) {
        //         if (i > 0 && results[i].puntaje_total === results[i - 1].puntaje_total) {
        //             currentIndex = results[i - 1].index;
        //         } else if (i === 0) {
        //             currentIndex = 1;
        //         } else {
        //             currentIndex = results[i - 1].index + 1;
        //         }
        //         results[i].index = currentIndex;
    
        //         const clubHeight = lineHeight;
        //         const puntajeHeight = lineHeight;
        //         const gimnastasHeight = lineHeight;
    
        //         // Check if next block of text will exceed the page height
        //         if (yOffset + clubHeight + puntajeHeight + gimnastasHeight + itemHeight > pageHeight) {
        //             doc.addPage();
        //             yOffset = marginTop;
        //         }
    
        //         doc.setFontSize(12).setFont(undefined, 'bold').text(`${results[i].index}. ${results[i].club}`, 15, yOffset);
        //         yOffset += clubHeight;
        //         doc.setFontSize(12).setFont(undefined, 'normal').text(`Puntaje: ${results[i].puntaje_total}`, 25, yOffset);
        //         yOffset += puntajeHeight;
        //         doc.text(`Gimnastas: ${results[i].nombres.join(', ')}`, 25, yOffset);
        //         yOffset += gimnastasHeight + itemHeight; // Add itemHeight for space between items
        //     }
        // }
    
        // // Save PDF
        // doc.save(`Resultados_equipo_${nivel}.pdf`);
    
    

    useEffect(() => {

        fetchNiveles();
    }, []);
    
    return (
        <div className='container'>

            {/* seleccionar aparato, nivel, categoría*/}
            <div className='row-select'>

                <div className='fila-select'>
                    <label for="nombre">
                        Nivel:
                    </label>
                    <select id="nombre" value={nivel} onChange={(e) => setNivel(e.target.value)}>
                        <option value="">--</option>
                        {niveles.map((name) => (
                            <option key={name} value={name}>
                                {name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className='fila-select'>
                    <label for='nombre'>
                        Gimnastas por equipo:
                    </label>
                    <input id="puntaje" value={numeroGimnastas} onChange={(e) => setNumeroGimnastas(e.target.value)}/>
                </div>

                <button className='boton-calcular' onClick={fetchResultsEquipo} type="submit">
                    Calcular
                </button>

            </div>

            {/* display  score */}
            {(results && results.length) > 0 ? (
            <div className='results'>
                <div>
                    <h2>Resultados Equipo {nivel}</h2>
                        {results.map((result, index, array) => {
                            let currentIndex = index + 1;
                            
                            if (index > 0 && result.puntaje_total === array[index - 1].puntaje_total) {
                                currentIndex = array[index - 1].index;
                            } else if (index === 0) {
                                currentIndex = 1;
                            } else {
                                currentIndex = array[index - 1].index + 1;
                            }

                            array[index].index = currentIndex;
                            result.currentIndex = currentIndex;
                            result.puntaje_total = parseFloat(result.puntaje_total).toFixed(3);
                    
                            return (
                                <div className='gimnasta-puntaje' key={index}>
                                    <p>
                                        <strong>{array[index].index}. {result.club}</strong>
                                    </p>
                                    <p>Puntaje: {result.puntaje_total}</p>
                                    <p>Gimnastas: {result.nombres.join(', ')}</p>
                                </div>
                            );
                        })}
                </div>
                <button className='descargar-button' onClick={descargarPDF}>Descargar PDF</button> 
            </div>
        ): (
            <div className='results'>
                <h2>No hay resultados</h2>
            </div>
        )}
            
            
        </div>
    );
};

export default ResultadosEquipo;
